import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { Stack, TextField, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { DashboardMetricByStore, StoreValue } from 'autogen/swagger/Dashboard';
import { useTableStyles } from 'shared/theme';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof StoreValue>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

interface EnhancedTableProps {
    onRequestSort: (event: React.MouseEvent<HTMLElement>, property: keyof StoreValue) => void;
    order: Order;
    orderBy: keyof StoreValue;
    valueSuffix?: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, valueSuffix } = props;
    const createSortHandler = (property: keyof StoreValue) => (event: React.MouseEvent<HTMLElement>) => {
        onRequestSort(event, property);
    };
    const { t } = useTypedTranslation();

    interface HeadCell {
        id: keyof StoreValue;
        label: string;
    }

    const headCells: readonly HeadCell[] = [
        {
            id: 'storeId',
            label: t('Dashboard', 'MetricDetails', 'StoreId'),
        },
        {
            id: 'storeName',
            label: t('Dashboard', 'MetricDetails', 'Store'),
        },
        {
            id: 'value',
            label: valueSuffix ? t('Shared', 'Percentage') : t('Shared', 'Amount'),
        },
    ];

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IMetricDetailsProps {
    dashboardMetricByStore: DashboardMetricByStore;
    valueSuffix?: string;
}

export function ListMetricDetails(props: IMetricDetailsProps): JSX.Element {
    const { dashboardMetricByStore, valueSuffix } = props;
    const { t } = useTypedTranslation();
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof StoreValue>('storeId');
    const [search, setSearch] = useState('');

    const handleRequestSort = (event: React.MouseEvent<HTMLElement>, property: keyof StoreValue) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const requestSearch = (searchedVal: string) => {
        setSearch(searchedVal);
    };

    const rows = dashboardMetricByStore.values.filter(
        (row) =>
            row.storeName.toLowerCase().includes(search.toLowerCase()) ||
            row.storeId.toString().includes(search.toLowerCase()),
    );

    const classes = useTableStyles();

    return (
        <Stack spacing={1}>
            <Typography variant="h4">{t('Dashboard', 'Metric', dashboardMetricByStore.metricType)}</Typography>
            <TextField
                label={t('Dashboard', 'MetricDetails', 'SearchDetails')}
                type="string"
                fullWidth
                value={search}
                variant="outlined"
                size="small"
                style={{ marginTop: '10px' }}
                onChange={(e): void => requestSearch(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <TableContainer component={Paper}>
                <Table size="small" style={{ tableLayout: 'auto' }} className={classes.root}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        valueSuffix={valueSuffix}
                    />
                    <TableBody>
                        {rows
                            .slice()
                            .sort(getComparator(order, orderBy))
                            .map((row, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        key={row.storeId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" id={labelId} scope="row">
                                            {row.storeId}
                                        </TableCell>
                                        <TableCell>
                                            {row.storeName !== '' ? row.storeName : `(${t('Shared', 'Deleted')})`}
                                        </TableCell>
                                        <TableCell>
                                            <Stack>
                                                {Math.round((row.value || 0) * 100) / 100}
                                                {valueSuffix || ''}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
}

export default ListMetricDetails;
