import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IFilter } from 'components/Input/Filter';
import { AuthRole, GetUserStoresDetailed, StoreDetailed } from 'autogen/swagger/Users';
import moment, { Moment } from 'moment/moment';
import { useTypedTranslation } from 'translations';
import { ExternalPartner, ListExternalPartners } from 'autogen/swagger/ExternalPartners';
import { Consultant, ListConsultantsByIds, SearchConsultant } from 'autogen/swagger/Consultant';
import { BookingType, DashboardRequestViewModel } from 'autogen/swagger/Dashboard';
import {
    ConsultationProductSearchModel,
    ListConsultationProductsByIds,
    SearchConsultationProduct,
} from 'autogen/swagger/ConsultationProduct';

interface IDashboardHistoryValues {
    fromDate?: string;
    toDate?: string;
    selectedStoreIds?: string;
    externalPartnerIds?: string;
    consultantIds?: string;
    consultationProductMasterIds?: string;
    bookingType?: BookingType;
}

export type IDashboardFilterValues = {
    fromDate: Moment;
    toDate: Moment;
    selectedStoreIds?: string[];
    externalPartnerIds?: string[];
    consultantIds?: string[];
    consultationProductMasterIds?: string[];
    bookingType: BookingType;
};

export function useDashboardFilters(
    role: AuthRole,
    selectedStoreId?: number,
): [
    IDashboardFilterValues,
    IFilter<IDashboardFilterValues>[],
    (values: IDashboardFilterValues) => void,
    () => DashboardRequestViewModel,
] {
    const history = useHistory();
    const historyValues = (history.location.state as IDashboardHistoryValues) || {};
    const [filterValues, setFilterValues] = useState<IDashboardFilterValues>({
        fromDate: historyValues.fromDate ? moment(historyValues.fromDate) : moment().startOf('day'),
        toDate: historyValues.toDate ? moment(historyValues.toDate) : moment().add(1, 'month').endOf('day'),
        // eslint-disable-next-line no-nested-ternary
        selectedStoreIds: historyValues.selectedStoreIds
            ? historyValues.selectedStoreIds.split(';')
            : selectedStoreId
            ? [selectedStoreId.toString()]
            : [],
        externalPartnerIds: historyValues.externalPartnerIds ? historyValues.externalPartnerIds.split(';') : [],
        consultationProductMasterIds: historyValues.consultationProductMasterIds
            ? historyValues.consultationProductMasterIds.split(';')
            : [],
        consultantIds: historyValues.consultantIds ? historyValues.consultantIds.split(';') : [],
        bookingType: historyValues.bookingType || 'Single',
    });

    const { t } = useTypedTranslation();
    const [filters, setFilters] = useState<IFilter<IDashboardFilterValues>[]>([]);
    const [storesDetailed, setStoresDetailed] = useState<StoreDetailed[]>();
    const [externalPartners, setExternalPartners] = useState<ExternalPartner[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await GetUserStoresDetailed();
            setStoresDetailed(result);
        })();

        (async (): Promise<void> => {
            let result = await ListExternalPartners({ onlyActive: false });
            result.splice(0, 0, {
                id: -1,
                name: t('ConsultationProduct', 'NoBrand'),
                isDeleted: false,
                isActive: true,
            });
            result = result.sort((a, b) => a.name.localeCompare(b.name));
            setExternalPartners(result);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filterStores = storesDetailed?.map((x) => ({
            name: x.name,
            id: x.id,
            districtId: x.districtId || 'ukendt',
            districtName: x.district?.name || '',
        }));
        setFilters([
            {
                label: t('Dashboard', 'Filter', 'BookingType'),
                paramName: 'bookingType',
                type: 'radio-buttons',
                float: 'left',
                options: [{ value: 'Single' }, { value: 'Event' }],
                getText: (value): string =>
                    t('Dashboard', 'BookingType', (value?.value?.toString() ?? '') as 'Single' | 'Event'),
                getValue: (value): string => value?.value?.toString() ?? '',
            },
            {
                label: t('Filter', 'FromDate'),
                paramName: 'fromDate',
                type: 'date',
                size: '200px',
            },
            {
                label: t('Filter', 'ToDate'),
                paramName: 'toDate',
                type: 'date',
                size: '200px',
            },
            {
                label: t('Dashboard', 'Stores'),
                paramName: 'selectedStoreIds',
                type: 'select-multiple',
                size: '300px',
                options: filterStores,
                getText: (value): string =>
                    value?.name?.toString() ? `${value?.id?.toString()} - ${value?.name?.toString()}` : '',
                getValue: (value): string => value?.id?.toString() ?? '',
                getGroup: (value): { id: string; name: string } => ({
                    id: value?.districtId?.toString() || '',
                    name: value?.districtName
                        ? `${value?.districtId?.toString()} - ${value?.districtName?.toString()}`
                        : value?.districtId?.toString() || '',
                }),
            },
            {
                label: t('Dashboard', 'Filter', 'ExternalPartner'),
                paramName: 'externalPartnerIds',
                type: 'select-multiple',
                options: externalPartners,
                getText: (value): string => value?.name?.toString() ?? '',
                getValue: (value): string => value?.id?.toString() ?? '',
                getGroup: (value): { id: string; name: string } => ({
                    id: value?.name?.toString().charAt(0) ?? '',
                    name: value?.name?.toString().charAt(0) ?? '',
                }),
            },
            {
                label: t('Dashboard', 'Filter', 'Consultants'),
                paramName: 'consultantIds',
                type: 'lookup-select-multiple',
                getText: (value): string => {
                    const val = value as Consultant;
                    const extPartNames = externalPartners
                        .filter((x) => val.externalPartnerIds.includes(x.id))
                        .map((x) => x.name);
                    return `${val.firstName} ${val.lastName}, ${val.email}, ${extPartNames.join(', ')}`;
                },
                getValue: (value): string => value?.id?.toString() ?? '',
                searchOptions: async (search: string) => SearchConsultant({ search }),
                lookupSelected: async (list: string[]) =>
                    ListConsultantsByIds({ consultantIds: list.map((x) => Number(x)) }),
            },
            {
                label: t('Dashboard', 'Filter', 'ConsultationProducts'),
                paramName: 'consultationProductMasterIds',
                type: 'lookup-select-multiple',
                getText: (value): string => {
                    const val = value as ConsultationProductSearchModel;
                    return `${val.name}${val.externalPartnerName ? `, ${val.externalPartnerName}` : ''}${
                        val.localStoreId
                            ? `, ${val.localStoreId} - ${
                                  storesDetailed?.find((store) => store.id === val.localStoreId)?.name ?? ''
                              }`
                            : ''
                    }`;
                },
                getValue: (value): string => value?.consultationProductMasterId?.toString() ?? '',
                getGroup: (value): { id: string; name: string } => ({
                    id: value?.categoryId?.toString() ?? '',
                    name: value?.categoryName?.toString() ?? '',
                }),
                alwaysShowGroups: true,
                searchOptions: async (search: string) => SearchConsultationProduct({ search }),
                lookupSelected: async (list: string[]) =>
                    ListConsultationProductsByIds({ consultationProductMasterIds: list.map((x) => Number(x)) }),
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role, storesDetailed, externalPartners]);

    function onFilterChange(values: IDashboardFilterValues): void {
        setFilterValues({ ...values, toDate: values.toDate.endOf('day') });
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            fromDate: values.fromDate.toISOString(true),
            toDate: values.toDate.endOf('day').toISOString(true),
            selectedStoreIds: values.selectedStoreIds?.join(';'),
            externalPartnerIds: values.externalPartnerIds?.join(';'),
            consultantIds: values.consultantIds?.join(';'),
            consultationProductMasterIds: values.consultationProductMasterIds?.join(';'),
            bookingType: values.bookingType,
        });
    }

    useEffect(() => {
        onFilterChange({ ...filterValues, selectedStoreIds: selectedStoreId ? [selectedStoreId.toString()] : [] });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedStoreId]);

    function getRequestValues(): DashboardRequestViewModel {
        const externalPartnerIds = (filterValues.externalPartnerIds || []).map((x) => Number(x));
        return {
            from: filterValues.fromDate.toISOString(true),
            to: filterValues.toDate.toISOString(true),
            bookingType: filterValues.bookingType,
            storeIds: (filterValues.selectedStoreIds || []).map((x) => Number(x)),
            consultantIds: (filterValues.consultantIds || []).map((x) => Number(x)),
            consultationProductMasterIds: (filterValues.consultationProductMasterIds || []).map((x) => Number(x)),
            externalPartnerIds: externalPartnerIds.filter((x) => x !== -1),
            filterNoExternalPartner: externalPartnerIds.some((x) => x === -1),
        };
    }

    return [filterValues, filters, onFilterChange, getRequestValues];
}

export default useDashboardFilters;
