/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from 'translations';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import {
    BookingWithUserDetailedModel,
    CancelBooking,
    ChangeConsultantForBooking,
    Consultant,
    GetAvailableConsultantsForBooking,
    GetDetailedBooking,
    SetCustomerAbsentForBooking,
} from 'autogen/swagger/Booking';
import { AuthRole } from 'autogen/swagger/Users';
import moment from 'moment';
import LabledText from 'components/display/LabledText';
import SpinnerButton from 'components/button/SpinnerButton';
import { IsInRole } from 'helpers/userHelper';
import { formatConsultationProductName } from 'helpers/string';

interface IEditBookingProps {
    id: number;
    role: AuthRole;
    onComplete?: (update: boolean) => void;
}

export function EditBooking(props: IEditBookingProps): JSX.Element {
    const { id, onComplete, role } = props;
    const { t } = useTypedTranslation();
    const [booking, setBooking] = useState<BookingWithUserDetailedModel>();
    const [values, setValues] = useState({
        consultantId: 0,
    });
    const [consultants, setConsultants] = useState<Consultant[]>([]);
    const [savingConsultant, setSavingConsultant] = useState(false);
    const [savingAbsentOrCancel, setSavingAbsentOrCancel] = useState(false);

    const isPastBooking = booking
        ? moment().isSameOrAfter(moment(booking?.bookingDetailed?.activeTime.activeFromDate), 'minute')
        : false;

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await GetDetailedBooking({ bookingId: id });
            setBooking(result);
            setValues({
                consultantId: result.bookingDetailed.consultant.id,
            });
        })();

        if ((role === 'AdminRole' || role === 'StoreManagerRole') && !isPastBooking) {
            (async (): Promise<void> => {
                const result = await GetAvailableConsultantsForBooking({ bookingId: id });
                setConsultants(result);
            })();
        } else {
            setConsultants([]);
        }
    }, [id, isPastBooking, role]);

    async function saveConsultant(): Promise<void> {
        try {
            setSavingConsultant(true);
            const result = await ChangeConsultantForBooking({ bookingId: id, consultantId: values.consultantId });

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSavingConsultant(false);
        }
    }

    async function onCancelBooking(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('Booking', 'ConfirmCancel'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await CancelBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    async function onCustomerAbsent(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('Booking', 'ConfirmCustomerAbsent'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await SetCustomerAbsentForBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    const customerAbsent = booking?.bookingDetailed.booking.status === 'UserWasAbsent';

    if (!booking)
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );

    return (
        <Grid container direction="column" justifyContent="flex-start" item xs gap={2}>
            <Grid container item>
                <Grid item xs>
                    <Typography variant="h4">
                        {formatConsultationProductName(
                            booking.bookingDetailed.consultationProductDetailed.name,
                            booking.bookingDetailed.consultationProductDetailed.consultationProductMasterDetailed
                                .externalPartner?.name,
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} lg={4} md={6}>
                    {booking.firstName ? (
                        <LabledText label={t('Shared', 'Customer')} text={`${booking.firstName} ${booking.lastName}`} />
                    ) : (
                        <LabledText
                            label={t('Shared', 'Customer')}
                            color="error"
                            text={t('Booking', 'CouldNotGetUser')}
                        />
                    )}
                </Grid>
                <Grid item lg={4} xs={12} md={6}>
                    <LabledText
                        label={t('Shared', 'TimeOfDay')}
                        text={`${moment(booking?.bookingDetailed.activeTime.activeFromDate).format(
                            'DD-MM-yyyy HH:mm',
                        )}-${moment(booking?.bookingDetailed.activeTime.activeToDate).format('HH:mm')}`}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item lg={4} xs={12} md={6}>
                    <LabledText label={t('Shared', 'ClubMatasMemberId')} text={`${booking?.clubMatasMemberId}`} />
                </Grid>
                {booking.mobileNumber && (
                    <Grid item lg={4} xs={12} md={6}>
                        <LabledText label={t('Shared', 'MobileNumber')} text={`${booking.mobileNumber}`} />
                    </Grid>
                )}
            </Grid>
            {booking?.bookingDetailed.booking.userComment && (
                <Grid container item spacing={2}>
                    <Grid item lg={12} xs={12} md={12}>
                        <LabledText
                            label={t('Shared', 'UserComment')}
                            text={`${booking?.bookingDetailed.booking.userComment}`}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container item spacing={2}>
                <Grid item xs={12} container spacing={2}>
                    {(role === 'AdminRole' || role === 'StoreManagerRole') && !isPastBooking ? (
                        <>
                            <Grid item xs={9}>
                                {consultants.length ? (
                                    <Autocomplete
                                        options={consultants}
                                        size="small"
                                        value={consultants.find((x) => values.consultantId === x.id) || null}
                                        onChange={(e, value): void =>
                                            setValues({
                                                ...values,
                                                consultantId: value?.id || 0,
                                            })
                                        }
                                        groupBy={(option): string =>
                                            option.isExternal ? t('Shared', 'External') : t('Shared', 'Internal')
                                        }
                                        getOptionLabel={(option): string => `${option.firstName} ${option.lastName}`}
                                        fullWidth
                                        renderInput={(params): JSX.Element => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label={t('Booking', 'ExecutedBy')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                ) : (
                                    <CircularProgress size={20} />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                                <SpinnerButton
                                    variant="contained"
                                    color="primary"
                                    disabled={
                                        !values.consultantId ||
                                        values.consultantId === booking.bookingDetailed.consultant.id
                                    }
                                    loading={savingConsultant}
                                    onClick={saveConsultant}
                                >
                                    {t('Shared', 'Save')}
                                </SpinnerButton>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={9}>
                            <LabledText
                                label={t('Booking', 'ExecutedBy')}
                                text={`${booking.bookingDetailed.consultant.firstName} ${booking.bookingDetailed.consultant.lastName}`}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {customerAbsent && (
                <Grid container item gap={2}>
                    <Grid item xs={12}>
                        <LabledText label={t('Shared', 'Status')} color="error" text={t('Booking', 'CustomerAbsent')} />
                    </Grid>
                </Grid>
            )}
            <Grid container item justifyContent="center" gap={2}>
                <Grid item>
                    <Button variant="contained" onClick={onCancelClick}>
                        {t('Shared', 'Back')}
                    </Button>
                </Grid>
                <Grid item>
                    {isPastBooking ? (
                        !customerAbsent && (
                            <SpinnerButton
                                variant="contained"
                                color="secondary"
                                onClick={onCustomerAbsent}
                                loading={savingAbsentOrCancel}
                            >
                                {t('Booking', 'CustomerAbsent')}
                            </SpinnerButton>
                        )
                    ) : IsInRole(role, 'AdminRole', 'StoreManagerRole', 'ConsultantRole') ? (
                        <SpinnerButton
                            variant="contained"
                            color="secondary"
                            onClick={onCancelBooking}
                            loading={savingAbsentOrCancel}
                        >
                            {t('Booking', 'CancelConsultation')}
                        </SpinnerButton>
                    ) : (
                        <>{null}</>
                    )}
                </Grid>
                <Grid item>
                    {booking.bookingDetailed.consultationProductDetailed.consultationProductMasterDetailed
                        .isVirtual && (
                        <Button
                            variant="contained"
                            color="success"
                            href={booking.virtualAgentUrl ?? ''}
                            target="_blank"
                            disabled={!booking.virtualAgentUrl}
                        >
                            {t('Booking', 'VirtualAgentUrl')}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EditBooking;
