import { useTranslation } from 'react-i18next';
import da from './localization/da';
import en from './localization/en';

// eslint-disable-next-line no-use-before-define
type Dictionary = string | DictionaryObject;
type DictionaryObject = { [K: string]: Dictionary };

interface TypedTFunction<D extends Dictionary> {
    <K extends keyof D>(args: K): D[K];
    <K extends keyof D, K1 extends keyof D[K]>(...args: [K, K1]): D[K][K1];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(...args: [K, K1, K2]): D[K][K1][K2];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2]>(...args: [K, K1, K2, K3]): D[K][K1][K2][K3];
    // ... up to a reasonable key parameters length of your choice ...
}

interface TypedFormatFunction<D extends Dictionary> {
    <K extends keyof D>(options: Record<string, Record<string, unknown> | string | number>, args: K): D[K];
    <K extends keyof D, K1 extends keyof D[K]>(
        options: Record<string, Record<string, unknown> | string | number>,
        ...args: [K, K1]
    ): D[K][K1];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1]>(
        options: Record<string, Record<string, unknown> | string | number>,
        ...args: [K, K1, K2]
    ): D[K][K1][K2];
    <K extends keyof D, K1 extends keyof D[K], K2 extends keyof D[K][K1], K3 extends keyof D[K][K1][K2]>(
        options: Record<string, Record<string, unknown> | string | number>,
        ...args: [K, K1, K2, K3]
    ): D[K][K1][K2][K3];
    // ... up to a reasonable key parameters length of your choice ...
}

export type transType = typeof en & typeof da;

export type TransFunction = TypedTFunction<transType>;

export type TransFormatFunction = TypedFormatFunction<transType>;

// import this hook in other modules instead of i18next useTranslation
export function useTypedTranslation(): { t: TransFunction; tf: TransFormatFunction } {
    const { t } = useTranslation();
    // implementation goes here: join keys by dot (depends on your config)
    // and delegate to lib t

    return {
        t(...keys: string[]): TypedTFunction<transType> {
            return t(keys.join('.'));
        },
        tf(
            options: Record<string, Record<string, unknown> | string | number>,
            ...keys: string[]
        ): TypedFormatFunction<transType> {
            return t(keys.join('.'), options);
        },
    };
}

export default useTypedTranslation;
