/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Booking';

export type StringMaybe = components['schemas']['StringMaybe'];
export type OperationResult = components['schemas']['OperationResult'];
export type ErrorType = components['schemas']['ErrorType'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
export type BookingStatus = components['schemas']['BookingStatus'];
export type Rating = components['schemas']['Rating'];
export type BookingFeedback = components['schemas']['BookingFeedback'];
export type BookingFeedbackMaybe = components['schemas']['BookingFeedbackMaybe'];
export type BookingFeedbackStatus = components['schemas']['BookingFeedbackStatus'];
export type ConsultationBooking = components['schemas']['ConsultationBooking'];
export type Location = components['schemas']['Location'];
export type Store = components['schemas']['Store'];
export type ConsultantNotificationFlags = components['schemas']['ConsultantNotificationFlags'];
export type Consultant = components['schemas']['Consultant'];
export type ExternalPartner = components['schemas']['ExternalPartner'];
export type ExternalPartnerMaybe = components['schemas']['ExternalPartnerMaybe'];
export type StoreConsultationProduct = components['schemas']['StoreConsultationProduct'];
export type BookingType = components['schemas']['BookingType'];
export type ConsultationProductMasterDetailed = components['schemas']['ConsultationProductMasterDetailed'];
export type ConsultationCategory = components['schemas']['ConsultationCategory'];
export type Medium = components['schemas']['Medium'];
export type MediumMaybe = components['schemas']['MediumMaybe'];
export type ConsultationProductMaster = components['schemas']['ConsultationProductMaster'];
export type ConsultationProductDetailed = components['schemas']['ConsultationProductDetailed'];
export type ConsultationBookingDetailed = components['schemas']['ConsultationBookingDetailed'];
export type BookingWithUserDetailedModel = components['schemas']['BookingWithUserDetailedModel'];
export type BookingCreateRequest = components['schemas']['BookingCreateRequest'];
export type Ticket = components['schemas']['Ticket'];
export type EventTime = components['schemas']['EventTime'];
export type EventBookingDetailed = components['schemas']['EventBookingDetailed'];
export type EventBookingWithUserDetailedModel = components['schemas']['EventBookingWithUserDetailedModel'];
export type EventBookingCreateViewModel = components['schemas']['EventBookingCreateViewModel'];
export type ClubMatasMemberModel = components['schemas']['ClubMatasMemberModel'];

type CancelBookingParams = paths['/Booking/CancelBooking']['post']['parameters']['query'];

type CancelBookingSuccess = paths['/Booking/CancelBooking']['post']['responses']['200']['content']['application/json'];

export const CancelBookingUrl = '/Booking/CancelBooking';

export const CancelBookingUrlFormat = (params: CancelBookingParams): string => CancelBookingUrl + formatParams(params);

export function CancelBooking(params: CancelBookingParams): Promise<CancelBookingSuccess> {
    return postQuery<CancelBookingSuccess>(`/Booking/CancelBooking${formatParams(params)}`, true);
}

type GetDetailedBookingParams = paths['/Booking/GetDetailedBooking']['get']['parameters']['query'];

type GetDetailedBookingSuccess =
    paths['/Booking/GetDetailedBooking']['get']['responses']['200']['content']['application/json'];

export const GetDetailedBookingUrl = '/Booking/GetDetailedBooking';

export const GetDetailedBookingUrlFormat = (params: GetDetailedBookingParams): string =>
    GetDetailedBookingUrl + formatParams(params);

export function GetDetailedBooking(params: GetDetailedBookingParams): Promise<GetDetailedBookingSuccess> {
    return fetchJSON<GetDetailedBookingSuccess>(`/Booking/GetDetailedBooking${formatParams(params)}`, {}, true);
}

type ChangeConsultantForBookingParams = paths['/Booking/ChangeConsultantForBooking']['post']['parameters']['query'];

export const ChangeConsultantForBookingUrl = '/Booking/ChangeConsultantForBooking';

export const ChangeConsultantForBookingUrlFormat = (params: ChangeConsultantForBookingParams): string =>
    ChangeConsultantForBookingUrl + formatParams(params);

export function ChangeConsultantForBooking(params: ChangeConsultantForBookingParams): Promise<unknown> {
    return postQuery<unknown>(`/Booking/ChangeConsultantForBooking${formatParams(params)}`, true);
}

type SetCustomerAbsentForBookingParams = paths['/Booking/SetCustomerAbsentForBooking']['post']['parameters']['query'];

export const SetCustomerAbsentForBookingUrl = '/Booking/SetCustomerAbsentForBooking';

export const SetCustomerAbsentForBookingUrlFormat = (params: SetCustomerAbsentForBookingParams): string =>
    SetCustomerAbsentForBookingUrl + formatParams(params);

export function SetCustomerAbsentForBooking(params: SetCustomerAbsentForBookingParams): Promise<unknown> {
    return postQuery<unknown>(`/Booking/SetCustomerAbsentForBooking${formatParams(params)}`, true);
}

type GetAvailableConsultantsForBookingParams =
    paths['/Booking/GetAvailableConsultantsForBooking']['get']['parameters']['query'];

type GetAvailableConsultantsForBookingSuccess =
    paths['/Booking/GetAvailableConsultantsForBooking']['get']['responses']['200']['content']['application/json'];

export const GetAvailableConsultantsForBookingUrl = '/Booking/GetAvailableConsultantsForBooking';

export const GetAvailableConsultantsForBookingUrlFormat = (params: GetAvailableConsultantsForBookingParams): string =>
    GetAvailableConsultantsForBookingUrl + formatParams(params);

export function GetAvailableConsultantsForBooking(
    params: GetAvailableConsultantsForBookingParams,
): Promise<GetAvailableConsultantsForBookingSuccess> {
    return fetchJSON<GetAvailableConsultantsForBookingSuccess>(
        `/Booking/GetAvailableConsultantsForBooking${formatParams(params)}`,
        {},
        true,
    );
}

type CreateBookingRequestBody = paths['/Booking/CreateBooking']['post']['requestBody']['content']['application/json'];

type CreateBookingSuccess = paths['/Booking/CreateBooking']['post']['responses']['200']['content']['application/json'];

export const CreateBookingUrl = '/Booking/CreateBooking';

export function CreateBooking(requestBody: CreateBookingRequestBody): Promise<CreateBookingSuccess> {
    return postJSON<CreateBookingSuccess>(`/Booking/CreateBooking`, requestBody, true);
}

type GetDetailedEventBookingParams = paths['/EventBooking/GetDetailedEventBooking']['get']['parameters']['query'];

type GetDetailedEventBookingSuccess =
    paths['/EventBooking/GetDetailedEventBooking']['get']['responses']['200']['content']['application/json'];

export const GetDetailedEventBookingUrl = '/EventBooking/GetDetailedEventBooking';

export const GetDetailedEventBookingUrlFormat = (params: GetDetailedEventBookingParams): string =>
    GetDetailedEventBookingUrl + formatParams(params);

export function GetDetailedEventBooking(
    params: GetDetailedEventBookingParams,
): Promise<GetDetailedEventBookingSuccess> {
    return fetchJSON<GetDetailedEventBookingSuccess>(
        `/EventBooking/GetDetailedEventBooking${formatParams(params)}`,
        {},
        true,
    );
}

type SetCustomerAbsentForEventBookingParams =
    paths['/EventBooking/SetCustomerAbsentForEventBooking']['post']['parameters']['query'];

export const SetCustomerAbsentForEventBookingUrl = '/EventBooking/SetCustomerAbsentForEventBooking';

export const SetCustomerAbsentForEventBookingUrlFormat = (params: SetCustomerAbsentForEventBookingParams): string =>
    SetCustomerAbsentForEventBookingUrl + formatParams(params);

export function SetCustomerAbsentForEventBooking(params: SetCustomerAbsentForEventBookingParams): Promise<unknown> {
    return postQuery<unknown>(`/EventBooking/SetCustomerAbsentForEventBooking${formatParams(params)}`, true);
}

type CancelEventBookingParams = paths['/EventBooking/CancelEventBooking']['post']['parameters']['query'];

export const CancelEventBookingUrl = '/EventBooking/CancelEventBooking';

export const CancelEventBookingUrlFormat = (params: CancelEventBookingParams): string =>
    CancelEventBookingUrl + formatParams(params);

export function CancelEventBooking(params: CancelEventBookingParams): Promise<unknown> {
    return postQuery<unknown>(`/EventBooking/CancelEventBooking${formatParams(params)}`, true);
}

type CreateEventBookingRequestBody =
    paths['/EventBooking/CreateEventBooking']['post']['requestBody']['content']['application/json'];

type CreateEventBookingSuccess =
    paths['/EventBooking/CreateEventBooking']['post']['responses']['200']['content']['application/json'];

export const CreateEventBookingUrl = '/EventBooking/CreateEventBooking';

export function CreateEventBooking(requestBody: CreateEventBookingRequestBody): Promise<CreateEventBookingSuccess> {
    return postJSON<CreateEventBookingSuccess>(`/EventBooking/CreateEventBooking`, requestBody, true);
}

type GetTicketPdfParams = paths['/EventBooking/GetTicketPdf']['get']['parameters']['query'];

type GetTicketPdfSuccess =
    paths['/EventBooking/GetTicketPdf']['get']['responses']['200']['content']['application/json'];

export const GetTicketPdfUrl = '/EventBooking/GetTicketPdf';

export const GetTicketPdfUrlFormat = (params: GetTicketPdfParams): string => GetTicketPdfUrl + formatParams(params);

export function GetTicketPdf(params: GetTicketPdfParams): Promise<GetTicketPdfSuccess> {
    return downloadFile<GetTicketPdfSuccess>(`/EventBooking/GetTicketPdf${formatParams(params)}`, true);
}

type GetTicketListPdfParams = paths['/EventBooking/GetTicketListPdf']['get']['parameters']['query'];

type GetTicketListPdfSuccess =
    paths['/EventBooking/GetTicketListPdf']['get']['responses']['200']['content']['application/json'];

export const GetTicketListPdfUrl = '/EventBooking/GetTicketListPdf';

export const GetTicketListPdfUrlFormat = (params: GetTicketListPdfParams): string =>
    GetTicketListPdfUrl + formatParams(params);

export function GetTicketListPdf(params: GetTicketListPdfParams): Promise<GetTicketListPdfSuccess> {
    return downloadFile<GetTicketListPdfSuccess>(`/EventBooking/GetTicketListPdf${formatParams(params)}`, true);
}

type LookupMemberParams = paths['/Member/LookupMember']['post']['parameters']['query'];

type LookupMemberSuccess = paths['/Member/LookupMember']['post']['responses']['200']['content']['application/json'];

export const LookupMemberUrl = '/Member/LookupMember';

export const LookupMemberUrlFormat = (params: LookupMemberParams): string => LookupMemberUrl + formatParams(params);

export function LookupMember(params: LookupMemberParams): Promise<LookupMemberSuccess> {
    return postQuery<LookupMemberSuccess>(`/Member/LookupMember${formatParams(params)}`, true);
}
