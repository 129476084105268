import React from 'react';
import {
    CategoriesUrl,
    getCategoriesUrl,
    CalendarUrl,
    ConsultationProductUrl,
    getConsultationProductUrl,
    ConsultantsUrl,
    getConsultantsUrl,
    ExternalPartnersUrl,
    getExternalPartnersUrl,
    DashboardUrl,
    OverviewUrl,
} from 'shared/urls';
import { transType } from 'translations';
import { RouteComponentProps } from 'react-router';
import { AuthRole } from 'autogen/swagger/Users';

import CalendarPage from 'pages/calendar/Calendar';
import ConsultationCategory from 'pages/consultations/ConsultaionCategory/ConsultationCategory';
import ConsultationProduct from 'pages/consultations/ConsultationProduct/ConsultationProducts';
import Consultants from 'pages/consultants/Consultants';
import ExternalPartner from 'pages/externalPartners/ExternalPartners';
import Dashboard from 'pages/dashboard/Dashboard';
import OverviewPage from 'pages/calendar/Overview';

export interface IRoute {
    path: string;
    label: keyof transType['Menu']['Links'];
    // eslint-disable-next-line @typescript-eslint/ban-types
    component: React.ComponentType<RouteComponentProps<object>> | React.ComponentType<Record<string, unknown>>;
    url?: string;
    allowedRoles?: AuthRole[];
    requireStoreId?: boolean;
}

const Routes: IRoute[] = [
    {
        path: DashboardUrl,
        label: 'Dashboard',
        component: Dashboard,
        allowedRoles: ['AdminRole', 'StoreManagerRole'],
    },
    {
        path: CategoriesUrl,
        url: getCategoriesUrl(),
        label: 'Categories',
        component: ConsultationCategory,
        allowedRoles: ['AdminRole'],
    },
    {
        path: ExternalPartnersUrl,
        url: getExternalPartnersUrl(),
        label: 'ExternalPartners',
        component: ExternalPartner,
        allowedRoles: ['AdminRole'],
    },
    {
        path: ConsultationProductUrl,
        url: getConsultationProductUrl(),
        label: 'ConsultationProduct',
        component: ConsultationProduct,
        allowedRoles: ['AdminRole', 'StoreManagerRole'],
    },
    {
        path: ConsultantsUrl,
        url: getConsultantsUrl(),
        label: 'Consultants',
        component: Consultants,
        allowedRoles: ['AdminRole', 'StoreManagerRole'],
    },
    {
        path: CalendarUrl,
        label: 'Calendar',
        component: CalendarPage,
    },
    {
        path: OverviewUrl,
        label: 'Overview',
        component: OverviewPage,
        allowedRoles: ['AdminRole', 'StoreManagerRole'],
    },
];

export default Routes;

export function getRouteUrl(route: IRoute): string {
    return route.url || route.path;
}

export function isActiveRoute(route: IRoute, pathname: string): boolean {
    if (pathname === '/' && pathname === getRouteUrl(route)) return true;

    if (getRouteUrl(route).length > 1) return pathname.indexOf(getRouteUrl(route)) > -1;

    return false;
}
